<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('device_manage.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('bedexitfacility'),
      // is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Mon_Menu_Facility'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'device_manage.status',
          field: 'status',
          width: '100px',
          change: function (value) {
            switch (value) {
              case 'DSON': return common.getI18n('device_manage.status_on')
              case 'DSOF': return common.getI18n('device_manage.status_off')
              case 'DSI': return common.getI18n('device_manage.status_unset')
            }

          }
        },
        {
          label: 'device_manage.mac',
          field: 'deviceMAC',
          width: '190px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'common.company',
          field: 'companyName',
          width: '200px',
        },
        {
          label: 'common.facility',
          field: 'facilityName',
          width: '200px',
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      header_actions: [
        // {
        //   text: 'common.add',
        //   variant: 'primary',
        //   fun: this.addData,
        //   permission: ''
        // },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Mon_Menu_Facility'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          // clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: []
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'status',
          label: 'device_manage.status',
          type: 'select',
          options: [
            {text: common.getI18n('device_manage.status_on'), value: 'DSON'},
            {text: common.getI18n('device_manage.status_off'), value: 'DSOF'},
            {text: common.getI18n('device_manage.status_unset'), value: 'DSI'},
          ]
        }

      ],
      search_default: {
        // companyID: common.getCompanyId(),
        // facilityID: common.getFacilityId(),
      }
    }
  },
  mounted() {
    common.getSearchCompanyOptions('companyID', this)
    // common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
    // common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    this.getList()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const params = Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      if (params.companyID && !params.facilityID) {
        params.facilityID = -1
      }
      this.$requestWehealth({
        method: 'get',
        url: '/bemonitor/GetFacilityMonitorList',
        params
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.monitors
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({name: 'bedexitfacility_add'})
    },
    editData: function (data) {
      this.$router.push({name: 'bedexitfacility_edit', params: {id: common.encrypt(data.monitorID)}})
    },
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },
  }
}
</script>
